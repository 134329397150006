
.commercial {
    padding: 60px 0;
    background: linear-gradient(to right, rgba(74, 108, 30, 1) 0%, rgba(109, 151, 48, 1) 100%);
    color: white;
}

.commercialContent {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    word-break: break-word;
    align-items: center;
    max-width: 80%;
    margin: 0 auto;
    justify-content: center;
}

.container {
}

.commercialLeft {
    width: 50%;
}

.commercialRight {
    width: 50%;
    color: black;

    background-color: #fff;
    border-radius: 8px;
    padding: 50px 73px 50px 72px;
}

.secondTitle h3{
    font-weight: bold;
    font-size: 35px;
    margin: 0;
}

.container > img {
    max-width: 100%;
}

.container > ul {
    padding-left: 15%;
}

.container > ul li {
    padding-left: 5px;
}

.container > ul li::marker {
    content: attr(data-emoji);
    text-shadow: -2px 2px 1px #0000009c;
    font-size: 25px;
}
/*.container > ul li:last-child {
    padding-left: 0;
    margin-left: 5px;
    line-height: 28.8px;
}
.container > ul li:last-child::marker {
    font-size: 18px;
}*/

@media (max-width: 1200px) {
    .commercialContent {
        flex-wrap: wrap
    }

    .commercialRight {
        margin-top: 20px;
        padding: calc(5%);
    }

    .commercialLeft, .commercialRight {
        width: 45%
    }
}

@media (max-width: 800px) {
    .commercialLeft, .commercialRight {
        width: 100%
    }
}