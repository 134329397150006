.home {
    display: flex;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: url('home.webp') no-repeat;
    background-position-x: 100%;
    background-position-y: 100%;
    background-size: cover;
    filter: brightness(1.15) contrast(105%);
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: rgb(113 110 110/25%);
    z-index: 1;
}

.homeContent {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 100vh;
    max-width: 90%;
    height: auto;
}

.homeLeft {
    align-self: center;
    width: 100%;
    color: white;
    text-shadow: -2px 2px 1px #0000009c;
    backdrop-filter: blur(2px);
    background: linear-gradient(to right, rgb(74 108 30) 0%, rgba(74, 108, 30, 0.6) 50%, rgb(255 255 255 / 0%) 100%);
    border-left: 5px solid #ffffff;
    padding: 20px 25px;

    z-index: 2;
}

.homeLogo {
    display: none;
}

.homeLeft ul{
    padding-left: 15px;
}

.homeLeft ul li {
    padding-left: 5px;
}

.homeLeft ul li::marker {
    content: attr(data-emoji);
    text-shadow: -2px 2px 1px #0000009c;
}

.homeRight {
    align-self: center;

    width: 100%;
    height: 100vh;

    z-index: 2;
}

.canvas {
    position: absolute;
    inset: 0;

    width: fit-content;
    height: fit-content;
}

.canvas::before {
    content: ' ';
    position: absolute;
    inset: 0;
    animation: levitation 3s infinite ease alternate;
    background: url('../../assets/commonAssets/lb1000.webp') no-repeat 55%;
    background-size: 60%;
    filter: brightness(1.15) contrast(80%);
}

@media (max-width: 1200px) {
    .homeContent { flex-wrap: wrap; min-height: 120vh; }
    .homeRight { display: none }
    .homeLogo {
        display: block;
        padding-top: 15px;
        margin-bottom: -5px;
        width: 150px;
        height: fit-content;
    }
    /*.aboutRight { margin-top: 20px; padding-left: 0 }
    .aboutLeft { width: 90% }*/
}

@keyframes levitation {
    to {
        transform: translateY(20px);
    }
}