
.supplies {
    padding: 70px calc(10%);
    background-color: #F7F9F4;
}

.suppliesContent {
    margin: 30px auto 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.secondTitle span {
    color: #4B6D1F;
}

.secondTitle h2 {
    font-weight: bold;
    font-size: 35px;
    margin: 0;
}

.secondTitle h2::before {
    content: '';
    background: url('../../assets/commonAssets/h2marker.svg') no-repeat left;
    width: 11px;
    height: 27px;
    display: inline-block;
    margin-right: 10px;
    /*filter: drop-shadow(-3px 4px 0px gray);*/
}

.suppliesItem {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 25%;
    height: 100%;
    margin: 0 6px;
}

.suppliesItem h3 {
    text-align: center;
    margin: 0;
}

.imgContainer {
    display: flex;
}

.imgContainer > img {
    max-width: 100%;
    height: 150px;
}

.description {
    margin: 18px 0;
    padding: 12px 20px;
    background-color: #fff;
    border-radius: 5px;
}

.description > ul {
    display: flex;
    flex-direction: column;

    padding-left: 5px;
    margin: 0;
}

.description > ul li {
    padding-left: 5px;
}

.description > ul span {
    color: #5b8127;
}

.description > ul li::marker {
    color: #608a24;
    content: attr(data-emoji);
    font-size: 25px;
}

.suppliesItem h4 {
    margin: 6px auto;
    font-size: 17px;
    font-weight: 500;
    color: black;
    border-radius: 8px;
}

.features {
    color: white;
    font-size: 16px;

    margin-bottom: 18px;
    background: linear-gradient(to right, rgba(74, 108, 30, 1) 0%, rgba(109, 151, 48, 1) 100%);
    padding: 15px;
    border-radius: 8px;
}

.features h3 {
    margin: 0 0 12px;
    font-size: 18px;
}

@media (max-width: 1024px) {
    .suppliesItem {
        width: 100%;
    }

    .suppliesContent {
        flex-wrap: wrap
    }
}

@media (max-width: 600px) {
    .suppliesContent {
        flex-wrap: wrap;
        width: 90%
    }
}