
.scheme {
    padding: 70px calc(10%);
}

.schemeContent {

}

.schemeLeft {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.schemeRight {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.middle {
    height: 70vh;
    position: relative;
    background: url('../../assets/commonAssets/scheme-abz3.png') no-repeat center 45% ;
    background-size: contain;
    margin-top: 18px;
}

.secondTitle span{
    color: #4B6D1F;
}
.secondTitle h2{
    font-weight: bold;
    font-size: 35px;
    margin: 0;
}

.secondTitle h2::before {
    content: '';
    background: url('../../assets/commonAssets/h2marker.svg') no-repeat left;
    width: 11px;
    height: 27px;
    display: inline-block;
    margin-right: 10px;
    /*filter: drop-shadow(-3px 4px 0px gray);*/
}

.schemeLeft > * > ul, .schemeRight > * > ul {
    padding-left: 15px;
    margin: 0;
}

.schemeLeft > * > ul li, .schemeRight > * > ul li {
    padding-left: 5px;
    font-size: 16px;
    font-weight: normal;
}

.schemeLeft > * > ul li::marker, .schemeRight > * > ul li::marker {
    color: #608a24;
    content: attr(data-emoji);
    font-size: 25px;
}

.schemeRight > * > p, .schemeLeft > * > p {
    margin-bottom: 0;
    border-bottom: 1px solid;
    color: #4b6d1f;
    font-weight: bold;
}

@media (max-width: 600px) {
    .schemeRight, .schemeLeft {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
@media (max-width: 750px) {
    .middle {
        background: url('../../assets/commonAssets/scheme-abz3.png') no-repeat 70%;
        height: 50vh;
        background-size: cover;
        margin-bottom: 0;
    }
}